import * as React from "react";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";

export default function OrderDetails(props) {
  const { onNext, value, setValue } = props;

  return (
    <div>
      <MDTypography>Order Details</MDTypography>
      <MDTypography variant="caption" color="text">
        Order Details are required for providing basic information of the order.
        For instance, order date and time.
      </MDTypography>
      <MDBox>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Date"
            value={value.date}
            inputFormat="DD/MM/YYYY"
            fullWidth
            onChange={(newValue) => {
              setValue({ ...value, date: newValue });
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </MDBox>

      <MDInput
        label="Comments"
        value={value?.comments}
        size="small"
        fullWidth
        multiline
        minRows={3}
        onChange={({ currentTarget }) => {
          setValue({ ...value, comments: currentTarget.value });
        }}
      />
      <MDBox
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        mt="1.5rem"
      >
        <MDButton
          variant="gradient"
          color="info"
          onClick={onNext}
        >
          Next
        </MDButton>
      </MDBox>
    </div>
  );
}
