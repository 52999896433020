import React from "react";
import { Icon, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddIcon from "@mui/icons-material/Add";
export function metaData() {
  return {
    columns: [
      { Header: "SKU", accessor: "sku", align: "left" },
      { Header: "Name", accessor: "name", align: "left" },
      // { Header: "Supplier", accessor: "supplier", align: "left" },
      { Header: "Used for", accessor: "used", align: "left" },
      { Header: "Quantity", accessor: "quantity", align: "left" },
      { Header: "Price", accessor: "price", align: "left" },
      {
        Header: "Total (kg/pcs/meter)",
        accessor: "totalWeight",
        align: "left",
      },
      { Header: "Property (weight)", accessor: "weight", align: "left" },
      { Header: "Property (gage)", accessor: "gage", align: "left" },
      { Header: "Purchase Unit", accessor: "purchase", align: "left" },
      { Header: "Production Unit", accessor: "production", align: "left" },
      // { Header: "Conversion", accessor: "conversion", align: "left" },
      { Header: "Added On", accessor: "added", align: "left" },
      { Header: "Last Updated", accessor: "updated", align: "left" },
      { Header: "Actions", accessor: "action", align: "center" },
    ],
  };
}

export function row(
  item,
  suppliers,
  addStock,
  handleHistory,
  handleEdit,
  handleDelete
) {
  return {
    sku: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {item.sku}
      </MDTypography>
    ),
    name: (
      <MDTypography
        variant="button"
        fontWeight="medium"
        sx={{ textTransform: "capitalize" }}
      >
        {item.name}
      </MDTypography>
    ),

    used: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {item.used_for}
      </MDTypography>
    ),
    quantity: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {item?.quantity}
      </MDTypography>
    ),
    totalWeight: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {item.purchase_unit === "kg"
          ? ((item?.quantity * item.weight) / 1000).toFixed(2)
          : (item?.quantity * item.weight).toFixed(2)}
      </MDTypography>
    ),
    price: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {Number(item.price).toFixed(2)}
      </MDTypography>
    ),
    weight: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {item.weight || "-"}
      </MDTypography>
    ),
    gage: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {item.gauge || "-"}
      </MDTypography>
    ),
    purchase: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {item.purchase_unit}
      </MDTypography>
    ),
    production: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {item.production_unit}
      </MDTypography>
    ),
    added: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {moment(item.createdAt).format("DD/MM/YYYY")}
      </MDTypography>
    ),
    updated: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {moment(item.updatedAt).format("DD/MM/YYYY")}
      </MDTypography>
    ),
    action: (
      <MDBox sx={{ display: "inline" }}>
        <IconButton onClick={() => addStock(item)}>
          <AddIcon fontSize="small"></AddIcon>
        </IconButton>
        <IconButton onClick={() => handleHistory(item)}>
          <Icon fontSize="small">history</Icon>
        </IconButton>
        <IconButton onClick={() => handleEdit(item)}>
          <Icon fontSize="small">edit</Icon>
        </IconButton>
        <IconButton onClick={() => handleDelete(item)}>
          <Icon fontSize="small">delete</Icon>
        </IconButton>
      </MDBox>
    ),
  };
}

export const mock = [
  {
    no: "1001",
    name: "Butterfly cut",
    supplier: "Supplier 2",
    quantity: [
      { value: "100", addedOn: new Date() },
      { value: "180", addedOn: new Date() },
    ],
    usedFor: "FootBar",
    weight: "105g",
    purchaseUnit: "Kg",
    sku: "Pur-20",
    conversion: "10 Pcs/kg",
  },
  {
    no: "1002",
    name: "Butterfly cut",
    supplier: "Supplier 1",
    quantity: [{ value: "80", addedOn: new Date() }],
    usedFor: "FootBar",
    weight: "165g",
    purchaseUnit: "Kg",
    sku: "Pur-20",
    conversion: "6 Pcs/kg",
  },
  {
    no: "1003",
    name: "Pipe",
    supplier: "Supplier 3",
    quantity: [{ value: "30", addedOn: new Date() }],
    usedFor: "Seat Stand",
    gage: "10",
    purchaseUnit: "Meter",
    sku: "Pur-20",
    conversion: "1 Pcs/Meter",
  },
];
