import { API_URL } from "services/Contants";
import { axoisInstance as axios } from "services/Contants";

const PartRequests = {
  addPart: (Part) => {
    return new Promise((resolve, reject) => {
      var data = JSON.stringify(Part);

      var config = {
        method: "post",
        url: `${API_URL}/part`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  addStock: (Stock) => {
    return new Promise((resolve, reject) => {
      var data = JSON.stringify(Stock);

      var config = {
        method: "post",
        url: `${API_URL}/part/purchase`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  removePart: (Part) => {
    return new Promise((resolve, reject) => {
      var config = {
        method: "delete",
        url: `${API_URL}/part?id=${Part}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  wastePart: (d) => {
    return new Promise((resolve, reject) => {
      var config = {
        method: "post",
        url: `${API_URL}/part/scrabbed`,
        headers: {
          "Content-Type": "application/json",
        },
        data: d,
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  getParts: (params) => {
    return new Promise((resolve, reject) => {
      var config = {
        method: "get",
        url: `${API_URL}/part`,
        params: params,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  updatePart: (Part, id) => {
    return new Promise((resolve, reject) => {
      var data = JSON.stringify({
        payload: Part,
      });

      var config = {
        method: "put",
        url: `${API_URL}/part?id=${id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  getPartsStats: () =>
    axios({
      method: "GET",
      url: `${API_URL}/part/stats`,
    }),
};

export default PartRequests;
