import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";

export default function StockHistoryModal(props) {
  const { onClose, showModal, data } = props;
  let hideCol = data.length > 0 ? data[0]?.supplier : false;
  return (
    <div>
      <Dialog open={showModal} onClose={onClose}>
        <DialogTitle>Stock History</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Stock History with quantity and date
          </DialogContentText>

          <div style={{ width: "100%" }}>
            <TableContainer sx={{ minWidth: 500 }} component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Sr.</TableCell>
                    {hideCol && <TableCell>Supplier</TableCell>}
                    <TableCell>Stock</TableCell>
                    {hideCol && <TableCell>Price</TableCell>}
                    <TableCell>Date</TableCell>
                  </TableRow>
                  {data.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      {hideCol && <TableCell>{row?.supplier?.name}</TableCell>}
                      <TableCell>{row?.quantity}</TableCell>
                      {hideCol && <TableCell>{row?.price}</TableCell>}
                      <TableCell>
                        {moment(row?.purchaseDate).format("DD/MM/YYYY")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {data.length < 1 && (
              <DialogContentText
                sx={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  textAlign: "center",
                  fontSize: "0.8rem",
                }}
              >
                No Stocks Available
              </DialogContentText>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
