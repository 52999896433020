import { LOGIN, LOGOUT } from "./types";

export const login = (token) => {
  return {
    type: LOGIN,
    payload: token,
  };
};

export const logout = () => ({
  type: LOGOUT,
});
