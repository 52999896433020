import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import {
  Autocomplete,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";

export default function PartsModal(props) {
  const {
    onClose,
    showModal,
    onSubmit,
    value,
    setValue,
    suppliers,
    onUpdate,
    loading,
  } = props;

  return (
    <div>
      <Dialog open={showModal} onClose={onClose}>
        <DialogTitle>Add New Part</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill in the basic details of part.
          </DialogContentText>

          {/* <Autocomplete
            id="search-part"
            size="small"
            options={suppliers?.map((option) => option.name)}
            renderInput={(params) => <TextField {...params} label="Supplier" />}
            onChange={(res, val) =>
              setValue({
                ...value,
                supplier: val,
                supplierId: suppliers.filter((item) => item.name == val)[0]._id,
              })
            }
            value={value?.supplier}
            sx={{ marginTop: "0.6rem" }}
          /> */}

          <MDInput
            label="Part Name"
            value={value?.name}
            size="small"
            fullWidth
            onChange={({ currentTarget }) => {
              setValue({ ...value, name: currentTarget.value });
            }}
          />
          {/* <MDInput
            label="Part Quantity"
            value={value?.quantity}
            size="small"
            fullWidth
            onChange={({ currentTarget }) => {
              setValue({ ...value, quantity: currentTarget.value });
            }}
          /> */}
          {/* <MDInput
            label="Price"
            value={value?.price}
            size="small"
            fullWidth
            onChange={({ currentTarget }) => {
              setValue({ ...value, price: currentTarget.value });
            }}
          /> */}

          <MDInput
            label="Used for"
            value={value?.used_for}
            size="small"
            fullWidth
            onChange={({ currentTarget }) => {
              setValue({ ...value, used_for: currentTarget.value });
            }}
          />

          {/* <MDInput
            label="Stock keeping unit (SKU)"
            value={value?.sku}
            size="small"
            fullWidth
            onChange={({ currentTarget }) => {
              setValue({ ...value, sku: currentTarget.value });
            }}
          /> */}
          <MDInput
            label="Property - weight (g)"
            value={value?.weight}
            size="small"
            fullWidth
            onChange={({ currentTarget }) => {
              setValue({ ...value, weight: currentTarget.value, gauge: null });
            }}
            disabled={value?.gauge}
            type="number"
          />
          <MDInput
            label="Property - gauge"
            value={value?.gauge}
            size="small"
            fullWidth
            onChange={({ currentTarget }) => {
              setValue({ ...value, gauge: currentTarget.value, weight: null });
            }}
            disabled={value?.weight}
            type="number"
          />

          <Autocomplete
            id="search-part"
            size="small"
            options={["kg", "pcs", "mtr"]?.map((option) => option)}
            renderInput={(params) => (
              <TextField {...params} label="Purchase unit" />
            )}
            onChange={(res, val) => setValue({ ...value, purchase_unit: val })}
            value={value?.purchase_unit}
            sx={{ marginTop: "0.6rem" }}
          />
          <Autocomplete
            id="search-part"
            size="small"
            options={["kg", "pcs", "mtr"].map((option) => option)}
            renderInput={(params) => (
              <TextField {...params} label="Production unit" />
            )}
            onChange={(res, val) =>
              setValue({ ...value, production_unit: val })
            }
            value={value?.production_unit}
            sx={{ marginTop: "0.6rem" }}
          />

          <MDInput
            label="Part Description"
            value={value?.textarea}
            size="small"
            multiline={true}
            minRows={3}
            fullWidth
            onChange={({ currentTarget }) => {
              setValue({ ...value, textarea: currentTarget.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={value?._id != undefined ? onUpdate : onSubmit}>
            {loading ? (
              <CircularProgress size={20} color="info" />
            ) : value?._id != undefined ? (
              "Update"
            ) : (
              "Add"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
