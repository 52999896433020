import { API_URL } from "services/Contants";
import { axoisInstance as axios } from "services/Contants";

const OrderRequest = {
  addOrder: (order) =>
    axios({
      method: "POST",
      url: `${API_URL}/orders`,
      headers: {
        "Content-Type": "application/json",
      },
      data: order,
    }),
  getOrders: (d) =>
    axios({
      method: "GET",
      url: `${API_URL}/orders`,
      params:d
    }),
    getOrdersStats: () =>
    axios({
      method: "GET",
      url: `${API_URL}/orders/stats/day`,
    }),
};

export default OrderRequest;
