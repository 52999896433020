import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { addCustomers } from "reduxStore/customer/action";
import CustomerRequests from "services/Customers/CustomerRequests";
import OrderRequest from "services/Order/OrderRequest";
import ProductRequests from "services/Products/ProductRequests";
import PartRequests from "services/Parts/PartRequests";
import moment from "moment";

function Dashboard() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const [unpaid, setUnPaid] = useState(0);
  const [partsStats, setPartsStats] = useState({});
  const [productStats, setProductStats] = useState({});

  const [currentWeekStats, setCurrentWeekStats] = useState({
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: { label: "Sold", data: [0, 0, 0, 0, 0, 0, 0] },
  });

  useEffect(() => {
    fetchData();
    fetchStats();
  }, []);

  const fetchStats = () => {
    OrderRequest.getOrdersStats()
      .then((res) => res.data)
      .then((res) => {
        let d = [];
        let days = {
          Mon: [],
          Tue: [],
          Wed: [],
          Thu: [],
          Fri: [],
          Sat: [],
          Sun: [],
        };
        res.data.currentWeek.forEach((el) => {
          let curr = moment(el.createdAt).format("ddd");
          console.log("Logs: ", curr);
          days[curr].push(el.salePrice);
        });
        d = Object.values(days).map((a) => {
          return a.reduce((c, d) => c + d, 0);
        });
        currentWeekStats.datasets.data = d;
        setCurrentWeekStats({ ...currentWeekStats });
      })
      .catch((rej) => {
        console.log(rej);
      })
      .finally(() => {});
    ProductRequests.getProductStats()
      .then((res) => res.data)
      .then((res) => {
        setProductStats({ ...res.data });
      })
      .catch((rej) => {
        console.log(rej.response);
      })
      .finally(() => {});
    PartRequests.getPartsStats()
      .then((res) => res.data)
      .then((res) => {
        setPartsStats({ ...res.data });
      })
      .catch((rej) => {
        console.log(rej);
      })
      .finally(() => {});
  };

  const fetchData = () => {
    CustomerRequests.getCustomers({
      limit: 0,
      offset: 0,
      query: "",
    }) //wrote by halfbloodprince
      .then((res) => {
        dispatch(addCustomers(res.data));
      })
      .catch((rej) => {
        console.log(rej);
      })
      .finally(() => {});
    CustomerRequests.unPaidInvoices()
      .then((res) => res.data)
      .then((res) => {
        let totalUnpaid = res?.data?.reduce((acc, curr) => {
          if (curr.salePrice !== undefined || curr.discount !== undefined) {
            acc = acc + (curr.salePrice - curr.discount);
          }
          return acc;
        }, 0);
        setUnPaid(totalUnpaid);
      })
      .finally(() => {});
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Total Raw Material"
                count={partsStats?.count || 0}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Total Raw Material Price"
                count={productStats?.totalPartPrice?.toFixed(2) || 0}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Total Products"
                count={productStats?.count || 0}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Total Products Price"
                count={productStats?.totalProductPrice?.toFixed(2) || 0}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Total Receivable (PKR)"
                count={unpaid || 0}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Products Sold"
                  description="Total Number of Products Sold in this week"
                  // date="campaign sent 2 days ago"
                  chart={currentWeekStats}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
