import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDInput from "components/MDInput";
import { metaData, row, mock } from "layouts/suppliers/data/suppliersTableData";
import UserRequets from "services/User/UserRequests";
import { useState, useEffect } from "react";
import team2 from "assets/images/team-2.jpg";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import moment from "moment";
import MDButton from "components/MDButton";
import Pagination from "@mui/material/Pagination";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SupplierModal from "components/CustomComponents/SupplierModal";
import SupplierRequests from "services/Supplier/SupplierRequests";
import { addSuppliers } from "reduxStore/supplier/action";

function Tables() {
  const { columns } = metaData();
  const token = useSelector((state) => state.user.token);
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [supplier, setSupplier] = useState({});
  const [totalPages, setTotalPages] = useState(10);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (arg) => {
    onAddSupplier();
    setSupplier(arg);
  };

  const handleDelete = (arg) => {
    handleClickOpen();
    setUserId(arg._id);
  };

  const deleteAccount = () => {
    handleClose();
    SupplierRequests.removeSupplier(userId)
      .then((res) => {
        fetchData();
      })
      .catch((rej) => {
        console.log(rej);
      });
  };

  const onAdd = () => {
    if (supplier.name == undefined || supplier.name == "") {
      alert("Please enter supplier name");
    } else if (supplier.contact == undefined || supplier.contact == "") {
      alert("Please enter supplier contact number");
    } else if (supplier.address == undefined || supplier.address == "") {
      alert("Please enter supplier address");
    } else {
      setUploading(true);
      SupplierRequests.addSupplier(supplier)
        .then((res) => {
          fetchData();
          setShowModal(false);
          setSupplier({});
        })
        .catch((rej) => {
          console.log(rej);
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const onUpdate = () => {
    if (supplier.name == undefined || supplier.name == "") {
      alert("Please enter supplier name");
    } else if (supplier.contact == undefined || supplier.contact == "") {
      alert("Please enter supplier contact number");
    } else if (supplier.address == undefined || supplier.address == "") {
      alert("Please enter supplier address");
    } else {
      setUploading(true);
      SupplierRequests.updateSupplier(
        {
          name: supplier.name,
          address: supplier.address,
          contact: supplier.contact,
        },
        supplier._id
      )
        .then((res) => {
          fetchData();
          setShowModal(false);
          setSupplier({});
        })
        .catch((rej) => {
          console.log(rej);
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const onAddSupplier = () => {
    setSupplier({});
    setShowModal(true);
  };

  const onSearchChange = (arg) => {
    setRows(
      data
        .filter((item) => {
          let nameLowerCase = item.name.toLowerCase();
          let searchTermLowerCase = arg.toLowerCase();
          return nameLowerCase.indexOf(searchTermLowerCase) > -1;
        })
        .map((item) => {
          return row(item, handleEdit, handleDelete);
        })
    );
  };

  const onChangePagination = (event, value) => {
    setPage(value);
    fetchData(value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (value) => {
    setLoading(true);
    const p = value ? value : page;
    SupplierRequests.getSupplier({
      limit: 10,
      offset: (p - 1) * 10,
      query: search,
    })
      .then(async (res) => {
        let results = await res.data.map((item) =>
          row(item, handleEdit, handleDelete)
        );
        dispatch(addSuppliers(res.data));
        setRows(results);
        setData(res.data);
        setTotalPages(Math.ceil(res.total / 10));
      })
      .catch((rej) => {
        console.log(rej);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  All Suppliers
                </MDTypography>
              </MDBox>

              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  searchComponent={
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p={3}
                    >
                      <MDBox
                        width="60%"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <MDButton
                          onClick={onAddSupplier}
                          variant="gradient"
                          color="info"
                          style={{ margin: "10px", width: 180 }}
                        >
                          {false ? (
                            <CircularProgress size={20} color="white" />
                          ) : (
                            "Add Supplier"
                          )}
                        </MDButton>
                      </MDBox>
                      {/* <MDBox width="12rem" ml="auto">
                        <MDInput
                          placeholder="Search..."
                          value={search}
                          size="small"
                          fullWidth
                          onChange={({ currentTarget }) => {
                            setSearch(currentTarget.value);
                            onSearchChange(currentTarget.value);
                          }}
                        />
                      </MDBox> */}
                    </MDBox>
                  }
                />

                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ marginBottom: "3rem", marginTop: "1rem" }}
                >
                  {loading ? (
                    <CircularProgress size={30} color="black" />
                  ) : rows.length < 1 ? (
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                    >
                      No supplier available
                    </MDTypography>
                  ) : null}
                </Grid>
                <Grid
                  container
                  spacing={3}
                  marginY={2}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Pagination
                    color="primary"
                    count={totalPages}
                    page={page}
                    onChange={onChangePagination}
                  />
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <SupplierModal
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        onSubmit={onAdd}
        onUpdate={onUpdate}
        supplier={supplier}
        setSupplier={setSupplier}
        loading={uploading}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Supplier?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure about deleting account? It cannot be recovered once
            deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={deleteAccount} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Tables;
