import { API_URL } from 'services/Contants';
import { axoisInstance as axios } from 'services/Contants';

const CustomerRequests = {
  addCustomer: (Customer) => {
    console.log({ Customer });
    return new Promise((resolve, reject) => {
      var data = JSON.stringify(Customer);

      var config = {
        method: 'post',
        url: `${API_URL}/customer`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  removeCustomer: (Customer) => {
    return new Promise((resolve, reject) => {
      var config = {
        method: 'delete',
        url: `${API_URL}/customer?id=${Customer}`,
        headers: {
          'Content-Type': 'application/json',
        },
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  getCustomers: ({ limit = 10, offset, query }) => {
    return new Promise((resolve, reject) => {
      var config = {
        method: 'get',
        url: `${API_URL}/customer?limit=${limit}&offset=${offset}&query=${query}`,
        headers: {
          'Content-Type': 'application/json',
        },
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  updateCustomer: (Customer, id) => {
    return new Promise((resolve, reject) => {
      var data = JSON.stringify({
        payload: Customer,
      });

      var config = {
        method: 'put',
        url: `${API_URL}/customer?id=${id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  unPaidInvoices:()=> axios({
    method: "POST",
    url: `${API_URL}/orders/unpaid`,
  }),
};

export default CustomerRequests;
