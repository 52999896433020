import React, { useRef } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDInput from "components/MDInput";
import { metaData, row } from "layouts/products/data/productsTableData";
import { useState, useEffect } from "react";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useSelector } from "react-redux";
import ProductModal from "components/CustomComponents/ProductModal";
import StockHistoryModal from "components/CustomComponents/StockHistoryModal";
import StockModal from "components/CustomComponents/StockModal";
import WasteProductModal from "components/CustomComponents/WasteProductModal";
import PartRequests from "services/Parts/PartRequests";
import ProductRequests from "services/Products/ProductRequests";
import Pagination from "@mui/material/Pagination";
const LIMIT = 10;

function Tables() {
  const { columns } = metaData();
  const token = useSelector((state) => state.user.token);
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [productId, setProductId] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [product, setProduct] = useState({});
  const [parts, setParts] = useState([]);
  const [stockModal, setStockModal] = useState(false);
  const [wasteProductModal, setWasteProductModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [totalPages, setTotalPages] = useState(10);
  const [page, setPage] = useState(1);
  const updateStockRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (arg) => {
    onAddProduct();
    updateStockRef.current = arg;
    setProduct(arg);
  };

  const handleHistory = (arg) => {
    setProduct(arg);
    setHistoryModal(true);
  };

  const handleDelete = (arg) => {
    handleClickOpen();
    setProductId(arg._id);
  };

  const deleteAccount = () => {
    handleClose();
    ProductRequests.removeProduct(productId)
      .then((res) => {
        fetchData();
      })
      .catch((rej) => {
        console.log(rej);
      });
  };

  const onAddProduct = () => {
    setProduct({});
    setShowModal(true);
  };

  const onAddStock = () => {
    setStockModal(true);
  };

  const onSearchChange = (arg) => {
    setRows(
      data
        .filter((item) => {
          let nameLowerCase = item.name.toLowerCase();
          let searchTermLowerCase = arg.toLowerCase();
          return nameLowerCase.indexOf(searchTermLowerCase) > -1;
        })
        .map((item, index) => {
          return row(
            item,
            index,
            addStock,
            handleHistory,
            handleEdit,
            handleDelete
          );
        })
    );
  };

  const onAdd = () => {
    if (product.name == undefined || product.name == "") {
      alert("Please enter product name");
    } else if (product.quantity == undefined || product.quantity == "") {
      alert("Please enter product quantity");
    } else if (product.weight == undefined || product.weight == "") {
      alert("Please enter product weight");
    } else if (product.parts == undefined || product.parts.length < 1) {
      alert("Please select at-least one part");
    } else {
      console.log("Add Product: ", { product });
      setUploading(true);
      ProductRequests.addProduct(product)
        .then((res) => {
          console.log(res);
          fetchData();
          setShowModal(false);
          setProduct({});
        })
        .catch((err) => {
          alert(err?.response?.data?.message);
          console.log(err?.response?.data?.message);
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const onUpdate = () => {
    if (product.name == undefined || product.name == "") {
      alert("Please enter product name");
    } else if (product.quantity == undefined || product.quantity == "") {
      alert("Please enter product quantity");
    } else if (product.weight == undefined || product.weight == "") {
      alert("Please enter product weight");
    } else if (product.parts == undefined || product.parts.length < 1) {
      alert("Please select at-least one part");
    } else {
      setUploading(true);
      ProductRequests.updateProduct(product)
        .then((res) => {
          console.log({ res });
          fetchData();
          setShowModal(false);
          setProduct({});
        })
        .catch((err) => {
          console.log("Error: ", err);
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const onUpdateProductStock = (p) => {
    if (p.quantity == undefined || p.quantity == "") {
      alert("Please enter product quantity");
    } else {
      setUploading(true);
      ProductRequests.updateProductStock({
        quantity: parseInt(p.quantity) + parseInt(p.oldQuantity),
        _id: p.partId,
        parts: p.parts,
      })
        .then((res) => {
          fetchData();
          setStockModal(false);
          setProduct({});
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const onChangePagination = (event, value) => {
    setPage(value);
    fetchData(value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const removeStockProduct = (stock) => {
    if (!stock.partId) {
      alert("Please select a part");
    } else if (!stock.quantity) {
      alert("Please enter qunatity for part");
    } else {
      setUploading(true);
      let d = {
        reason: stock.reason,
        quantity: stock.quantity,
        id: stock.partId,
      };
      ProductRequests.wasteProduct(d)
        .then((res) => {
          fetchData();
        })
        .catch((rej) => {
          alert(rej.response.data.message);
        })
        .finally(() => {
          setUploading(false);
          setWasteProductModal(false);
        });
    }
  };

  const fetchData = (value) => {
    setLoading(true);
    const p = value ? value : page;

    PartRequests.getParts({
      limit: 0,
      offset: 0,
      query: "",
      sku: "",
    })
      .then(async (res) => {
        setParts(res.data);
      })
      .catch((rej) => {
        console.log(rej);
      })
      .finally(() => {});

    ProductRequests.getProducts({
      limit: LIMIT,
      offset: (p - 1) * LIMIT,
      query: search,
    })
      .then(async (res) => {
        let results = await res.data.map((item, index) =>
          row(item, index, addStock, handleHistory, handleEdit, handleDelete)
        );
        setRows(results);
        setData([...res.data]);
        setTotalPages(Math.ceil(res.total / LIMIT));
      })
      .catch((rej) => {
        console.log(rej);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addStock = (arg) => {
    // arg
    updateStockRef.current = arg;
    setStockModal(true);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Products
                </MDTypography>
              </MDBox>

              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  searchComponent={
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <MDBox
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          ml={2}
                          mr={2}
                          mb={2}
                        >
                          <MDButton
                            onClick={onAddProduct}
                            variant="gradient"
                            color="info"
                            style={{ margin: "10px", width: 180 }}
                          >
                            Add Product
                          </MDButton>
                          {/* <MDButton
                            onClick={onAddStock}
                            variant="gradient"
                            color="info"
                            style={{ margin: "10px", width: 180 }}
                          >
                            Add New Stock
                          </MDButton> */}
                          <MDButton
                            onClick={() => setWasteProductModal(true)}
                            variant="gradient"
                            color="error"
                            style={{ margin: "10px", width: 180 }}
                          >
                            Waste Product
                          </MDButton>
                          {/* <MDBox ml="auto">
                            <MDInput
                              placeholder="Search..."
                              value={search}
                              size="small"
                              fullWidth
                              onChange={({ currentTarget }) => {
                                setSearch(currentTarget.value);
                                onSearchChange(currentTarget.value);
                              }}
                            />
                          </MDBox> */}
                        </MDBox>
                      </Grid>
                    </Grid>
                  }
                />
                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ marginBottom: "3rem", marginTop: "1rem" }}
                >
                  {loading ? (
                    <CircularProgress size={30} color="black" />
                  ) : rows.length < 1 ? (
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                    >
                      No products available
                    </MDTypography>
                  ) : null}
                </Grid>
                <Grid
                  container
                  spacing={3}
                  marginY={2}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Pagination
                    color="primary"
                    count={totalPages}
                    page={page}
                    onChange={onChangePagination}
                  />
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <ProductModal
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        onSubmit={onAdd}
        onUpdate={onUpdate}
        value={product}
        selectedItem={updateStockRef.current}
        setValue={setProduct}
        data={parts}
        loading={uploading}
      />
      <StockModal
        showModal={stockModal}
        onClose={() => {
          setStockModal(false);
        }}
        selectedItem={updateStockRef.current}
        data={data}
        onSubmit={onUpdateProductStock}
        type="product"
      />
      <WasteProductModal
        showModal={wasteProductModal}
        onClose={() => {
          setWasteProductModal(false);
        }}
        data={data}
        onSubmit={removeStockProduct}
        type="product"
        loading={uploading}
      />
      <StockHistoryModal
        showModal={historyModal}
        onClose={() => {
          setHistoryModal(false);
        }}
        data={product?.history || []}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Product?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure about deleting account? It cannot be recovered once
            deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={deleteAccount} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Tables;
