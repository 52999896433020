import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import { CircularProgress } from "@mui/material";

export default function FormDialog(props) {
  const { onClose, showModal, onSubmit,onUpdate, supplier, setSupplier,loading } = props;

  return (
    <div>
      <Dialog open={showModal} onClose={onClose}>
        <DialogTitle>Add supplier</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill in the basic details of your supplier.
          </DialogContentText>
          <MDInput
            label="Full Name *"
            value={supplier?.name}
            size="small"
            fullWidth
            onChange={({ currentTarget }) => {
              setSupplier({ ...supplier, name: currentTarget.value });
            }}
          />
          <MDInput
            label="Phone *"
            value={supplier?.contact}
            size="small"
            fullWidth
            onChange={({ currentTarget }) => {
              setSupplier({ ...supplier, contact: currentTarget.value });
            }}
          />
          <MDInput
            label="Address *"
            value={supplier?.address}
            size="small"
            fullWidth
            multiline={true}
            minRows={3}
            onChange={({ currentTarget }) => {
              setSupplier({ ...supplier, address: currentTarget.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={supplier?._id != undefined ?onUpdate:onSubmit}>
            {loading?<CircularProgress size={20} color="info" />:supplier?._id != undefined ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
