import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Divider } from "@mui/material";
import { Box } from "@mui/system";
import ProgressTimeline from "./components/ProgressTimeline";
import OrderDetails from "./components/OrderDetails";
import PaymentDetails from "./components/PaymentDetails";
import PartDetails from "./components/PartDetails";
import CustomerDetails from "./components/CustomerDetails";
import ProductRequests from "../../services/Products/ProductRequests";
import moment from "moment";

const LIMIT = 10;

function CreateOrder({ onSubmit, customers }) {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.token);
  const [activeTab, setActiveTab] = useState("Order");
  const [orderDetails, setOrderDetails] = useState({
    date: new Date(),
    comments: "",
  });
  const [customerDetails, setCustomerDetails] = useState({});
  const [partDetails, setPartDetails] = useState({});
  const [parts, setParts] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState({});
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = () => {
    ProductRequests.getProducts({
      limit: 0,
      offset: 0,
      query: "",
    })
      .then(async (res) => {
        setProducts(res.data);
      })
      .catch((rej) => {
        console.log(rej);
      })
      .finally(() => {});
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pt: "5%",
      }}
    >
      <ProgressTimeline active={activeTab} />
      <MDBox
        sx={{
          px: "2%",
          py: "2%",
          mt: "5%",
          width: "100%",
        }}
      >
        {activeTab === "Order" && (
          <OrderDetails
            onNext={(res) => {
              setActiveTab("Customer");
            }}
            value={orderDetails}
            setValue={setOrderDetails}
          />
        )}
        {activeTab === "Customer" && (
          <CustomerDetails
            onBack={() => setActiveTab("Order")}
            onNext={(customer) => {
              setCustomerDetails(customer);
              setActiveTab("Part");
            }}
            customers={customers}
          />
        )}
        {activeTab === "Part" && (
          <PartDetails
            onBack={() => setActiveTab("Customer")}
            onNext={() => {
              setActiveTab("Payment");
              let total = 0;
              parts.map((item) => {
                total += parseFloat(item.price);
              });
              setPaymentDetails({ ...paymentDetails, total: total });
              // if (
              //   partDetails?.name &&
              //   partDetails?.quantity &&
              //   partDetails?.price
              // )
              //   setParts([...parts, partDetails]);
            }}
            products={products}
            value={partDetails}
            setValue={setPartDetails}
            valueArr={parts}
            setValueArr={setParts}
          />
        )}
        {activeTab == "Payment" && (
          <PaymentDetails
            onBack={() => setActiveTab("Part")}
            onNext={() => {
              onSubmit({
                status: "Processing",
                statusCode: 0,
                ...orderDetails,
                ...customerDetails,
                parts,
                ...paymentDetails,
                // date: orderDetails?.date,
                // comments: orderDetails?.comments,
                // customerName: customerDetails?.name,
                // customerPhone: customerDetails?.phone,
                // customerAddress: customerDetails?.address,
                // products: parts,
                // revenue: paymentDetails?.total,
                // discount: paymentDetails?.discount,
                // address: paymentDetails?.address,
              });
            }}
            value={paymentDetails}
            setValue={setPaymentDetails}
          />
        )}
      </MDBox>
    </Container>
  );
}

export default CreateOrder;
