import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDInput from "components/MDInput";
import { metaData, row, mock } from "layouts/orders/data/ordersTableData";
import UserRequets from "services/User/UserRequests";
import { useState, useEffect } from "react";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import OrderModal from "components/CustomComponents/OrderModal";
import { addCustomers } from "reduxStore/customer/action";
import CustomerRequests from "services/Customers/CustomerRequests";
import OrderRequest from "services/Order/OrderRequest";
import Pagination from "@mui/material/Pagination";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function Tables() {
  const { columns } = metaData();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const customers = useSelector((state) => state.customer.customers);

  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [part, setPart] = useState({});
  const [totalPages, setTotalPages] = useState(10);
  const [page, setPage] = useState(1);
  const [age, setAge] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteAccount = () => {
    handleClose();
  };
  const onCreateOrder = () => {
    setShowModal(true);
  };

  const onSearchChange = (arg) => {
    // setRows(
    //   mock
    //     .filter((item) => {
    //       let nameLowerCase = item.name.toLowerCase();
    //       let searchTermLowerCase = arg.toLowerCase();
    //       return nameLowerCase.indexOf(searchTermLowerCase) > -1;
    //     })
    //     .map((item) => {
    //       return row(item, handleDelete);
    //     })
    // );
  };

  useEffect(() => {
    fetchData();
    getOrders();
  }, []);
  const onChangePagination = (event, value) => {
    setPage(value);
    getOrders(value);
  };

  const fetchData = () => {
    setLoading(true);
    CustomerRequests.getCustomers({
      limit: 9999,
      offset: 0,
      query: "",
    })
      .then((res) => {
        dispatch(addCustomers(res.data));
      })
      .catch((rej) => {
        console.log(rej);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getOrders = (value) => {
    setLoading(true);
    const p = value ? value : page;

    OrderRequest.getOrders({
      limit: 10,
      offset: (p - 1) * 10,
      customer: search,
    })
      .then((res) => res.data)
      .then(async (res) => {
        let results = await res.data.map((item) => row(item));
        setRows(results);
        setData(res.data);
        setTotalPages(Math.ceil(res.total / 10));
      })
      .catch((rej) => {
        console.log(rej);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmitOrder = (order) => {
    setShowModal(false);
    setLoading(true);
    let data = {
      customer: order._id,
      products: order.parts.map((a) => {
        return {
          _id: a.productId,
          quantity: a.quantity,
          price: parseInt(a.price),
        };
      }),
      comments: order.comments || "",
      dueDate: order.date._d,
      discount: order.discount || 0,
      billingAddress: order.billingAddress,
      status: order.paid ? "paid" : "unpaid",
      salePrice: order.total,
    };
    OrderRequest.addOrder(data)
      .then((res) => res.data)
      .then((res) => {
        console.log("Response on add", res);
        getOrders();
      })
      .catch((err) => console.log(err.response))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Orders
                </MDTypography>
              </MDBox>

              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  searchComponent={
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p={3}
                    >
                      <MDBox
                        width="60%"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <MDButton
                          onClick={onCreateOrder}
                          variant="gradient"
                          color="info"
                          style={{ margin: "10px", width: 180 }}
                        >
                          {false ? (
                            <CircularProgress size={20} color="white" />
                          ) : (
                            "Create Order"
                          )}
                        </MDButton>
                        {/* <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Filter By status
                          </InputLabel>
                          <Select
                            // labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={age}
                            label="Age"
                            onChange={handleChange}
                            style={{
                              margin: "10px",
                              width: 180,
                              padding: 10,
                            }}
                          >
                            <MenuItem value={"paid"}>Paid</MenuItem>
                            <MenuItem value={"unpaid"}>UnPaid</MenuItem>
                            <MenuItem value={"returned"}>Returned</MenuItem>
                          </Select>
                        </FormControl> */}
                      </MDBox>
                      {/* <MDBox width="12rem" ml="auto">
                        <MDInput
                          placeholder="Search..."
                          value={search}
                          size="small"
                          fullWidth
                          onChange={({ currentTarget }) => {
                            setSearch(currentTarget.value);
                            onSearchChange(currentTarget.value);
                          }}
                        />
                      </MDBox> */}
                    </MDBox>
                  }
                />

                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ marginBottom: "3rem", marginTop: "1rem" }}
                >
                  {loading ? (
                    <CircularProgress size={30} color="black" />
                  ) : rows.length < 1 ? (
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                    >
                      No orders available
                    </MDTypography>
                  ) : null}
                </Grid>
                <Grid
                  container
                  spacing={3}
                  marginY={2}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Pagination
                    color="primary"
                    count={totalPages}
                    page={page}
                    onChange={onChangePagination}
                  />
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <OrderModal
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        onSubmit={(res) => {
          onSubmitOrder(res);
          // setPart({});
        }}
        customers={customers}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Order?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure about deleting account? It cannot be recovered once
            deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={deleteAccount} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Tables;
