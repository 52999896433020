import { Icon, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";

export function metaData() {
  return {
    columns: [
      { Header: "supplier", accessor: "supplier", width: "20%", align: "left" },
      { Header: "address", accessor: "address", width: "20%", align: "left" },
      { Header: "added on", accessor: "added", align: "center" },
      { Header: "actions", accessor: "action", align: "center" },
    ],
  };
}

export function row(item, handleEdit, handleDelete) {
  return {
    supplier: (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDBox lineHeight={1}>
          <MDTypography
            display="block"
            variant="button"
            fontWeight="medium"
            sx={{ textTransform: "capitalize" }}
          >
            {item.name}
          </MDTypography>
          <MDTypography variant="caption">{item.contact}</MDTypography>
        </MDBox>
      </MDBox>
    ),
    address: (
      <MDTypography display="block" variant="caption" sx={{ width: "25vw" }}>
        {item.address}
      </MDTypography>
    ),
    added: (
      <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {moment(item.createdAt).format("DD/MM/YYYY")}
      </MDTypography>
    ),
    action: (
      <MDBox sx={{ display: "inline" }}>
        <IconButton onClick={() => handleEdit(item)}>
          <Icon fontSize="small">edit</Icon>
        </IconButton>
        <IconButton onClick={() => handleDelete(item)}>
          <Icon fontSize="small">delete</Icon>
        </IconButton>
      </MDBox>
    ),
  };
}

export const mock = [
  {
    id: "1",
    name: "Anas",
    phone: "123456789",
    address: "105-107 C S.I.E. Punjab, Lahore,Punjab,Pakistan.",
  },
];
