import { API_URL } from 'services/Contants';
import { axoisInstance as axios } from 'services/Contants';

const SupplierRequests = {
  addSupplier: (Supplier) => {
    return new Promise((resolve, reject) => {
      var data = JSON.stringify(Supplier);

      var config = {
        method: 'post',
        url: `${API_URL}/supplier`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  removeSupplier: (Supplier) => {
    return new Promise((resolve, reject) => {
      var config = {
        method: 'delete',
        url: `${API_URL}/supplier?id=${Supplier}`,
        headers: {
          'Content-Type': 'application/json',
        },
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  getSupplier: ({ limit = 10, offset, query }) => {
    return new Promise((resolve, reject) => {
      var config = {
        method: 'get',
        url: `${API_URL}/supplier?limit=${limit}&offset=${offset}&query=${query}`,
        headers: {
          'Content-Type': 'application/json',
        },
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  updateSupplier: (Supplier, id) => {
    return new Promise((resolve, reject) => {
      var data = JSON.stringify({
        payload: Supplier,
      });

      var config = {
        method: 'put',
        url: `${API_URL}/supplier?id=${id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
};

export default SupplierRequests;
