import { API_URL } from "services/Contants";
import { axoisInstance as axios } from "services/Contants";

const ProductRequests = {
  addProduct: (Product) => {
    return new Promise((resolve, reject) => {
      var data = JSON.stringify(Product);

      var config = {
        method: "post",
        url: `${API_URL}/product`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  removeProduct: (Product) => {
    return new Promise((resolve, reject) => {
      var config = {
        method: "delete",
        url: `${API_URL}/product?id=${Product}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  wasteProduct: (d) => {
    return new Promise((resolve, reject) => {
      var config = {
        method: "post",
        url: `${API_URL}/product/scrabbed`,
        headers: {
          "Content-Type": "application/json",
        },
        data: d,
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  getProducts: (params) => {
    return new Promise((resolve, reject) => {
      let config = {
        method: "get",
        url: `${API_URL}/product`,
        params: params,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  updateProduct: (Product) => {
    return new Promise((resolve, reject) => {
      let data = {
        payload: {
          parts: Product.parts,
          name: Product.name,
          weight: Product.weight,
          quantity: Product.quantity,
        },
      };
      console.log("Product Update Data: ", { data });
      var config = {
        method: "put",
        url: `${API_URL}/product?id=${Product._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  updateProductStock: (Product) => {
    return new Promise((resolve, reject) => {
      let data = JSON.stringify({
        payload: {
          quantity: Product.quantity,
          parts: Product.parts,
        },
      });

      var config = {
        method: "put",
        url: `${API_URL}/product?id=${Product._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  getProductStats: () =>
    axios({
      method: "GET",
      url: `${API_URL}/product/stats`,
    }),
};

export default ProductRequests;
