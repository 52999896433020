import * as React from "react";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { colors, Icon, IconButton, Paper } from "@mui/material";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";

export default function PartDetails(props) {
  const { onBack, onNext, valueArr, setValueArr, products } = props;
  const [item, setItem] = React.useState({});

  return (
    <div>
      <MDTypography>Product Details</MDTypography>
      <MDTypography variant="caption" color="text">
        Product Details are required.
      </MDTypography>

      <Autocomplete
        id="search-part"
        size="small"
        options={products?.map((option) => option.name)}
        renderInput={(params) => (
          <TextField {...params} label={"Search Product"} />
        )}
        onChange={(res, v) => {
          let SalePrice = products
            .filter((a) => a.name === v)[0]
            .parts.map((a) => a?._id?.price * a?.quantity)
            .filter((e) => e)
            .reduce((partialSum, a) => partialSum + a, 0);

          setItem({
            ...item,
            productName: v,
            productId: products.filter((a) => a.name === v)[0]._id,
            quantityOnHand: products.filter((a) => a.name === v)[0].quantity,
            SalePrice: SalePrice.toFixed(2),
          });
        }}
        value={item?.productName}
        sx={{ marginTop: "2rem", marginBottom: "0.1rem" }}
      />
      <MDInput
        label="Sale Price per product"
        value={item.SalePrice || 0}
        size="small"
        fullWidth
        type="number"
        disabled={true}
      />
      <MDInput
        label="Quantity"
        value={item?.quantity}
        size="small"
        fullWidth
        onChange={({ currentTarget }) => {
          if (currentTarget.value > item.quantityOnHand) {
            alert("Selling quantity can not be greater than quantity in hand.");
          } else {
            setItem({ ...item, quantity: currentTarget.value });
          }
        }}
        type="number"
      />
      <MDInput
        label="Total Price"
        value={item?.price}
        size="small"
        fullWidth
        onChange={({ currentTarget }) => {
          // const re = /^[0-9\b]+$/;
          // if (currentTarget.value === "" || re.test(currentTarget.value))
          setItem({ ...item, price: currentTarget.value });
        }}
        type="number"
      />
      <MDBox display="flex" my="1rem" alignItems="center" flexWrap="wrap">
        {valueArr.map((item, index) => (
          <MDBox
            key={index}
            display="flex"
            alignItems="center"
            flexDirection="column"
            mb="2%"
            mr="2rem"
          >
            <Paper
              key={index}
              elevation={1}
              sx={{
                backgroundColor: "#fff",
                display: "flex",
                flexDirection: "column",
                px: "1rem",
                py: "0.5rem",
                borderRadius: "5px",
                width: "auto",
                overflow: "wrap",
              }}
            >
              <MDTypography variant="button">
                {item.productName} ({item.quantity})
              </MDTypography>
              <MDTypography variant="button">
                {new Intl.NumberFormat("pk-PK", {
                  style: "currency",
                  currency: "PKR",
                }).format(item.price)}
              </MDTypography>
            </Paper>
            <MDTypography
              onClick={() =>
                setValueArr(valueArr.filter((it, i) => i != index))
              }
              variant="caption"
              color="text"
              mt="0.5rem"
              sx={{ cursor: "pointer" }}
            >
              Remove
            </MDTypography>
          </MDBox>
        ))}

        <IconButton
          onClick={() => {
            if (
              item?.productName &&
              item.productId &&
              item?.quantity &&
              item?.price
            )
              setValueArr([...valueArr, item]);
          }}
        >
          <Icon fontSize="large" color="action">
            playlist_add
          </Icon>
        </IconButton>
      </MDBox>

      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt="2.5rem"
      >
        <MDButton variant="gradient" color="secondary" onClick={onBack}>
          Back
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          onClick={onNext}
          disabled={valueArr.length === 0}
        >
          Next
        </MDButton>
      </MDBox>
    </div>
  );
}
