import { SUPPLIER,RESET } from "./types";

export const addSuppliers = (supplier) => {
  return {
    type: SUPPLIER,
    payload: supplier,
  };
};
export const resetSupplier = () => ({
  type: RESET,
});
