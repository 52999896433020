import { SUPPLIER, RESET } from "./types";

const initialState = {
  suppliers: null,
};

const SupplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUPPLIER:
      return {
        ...state,
        suppliers: action.payload,
      };
      case RESET:
        return {
          ...state,
          suppliers: null,
        };

    default:
      return state;
  }
};

export default SupplierReducer;
