import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import CreateOrder from "layouts/createorder";

export default function PartsModal(props) {
  const { onClose, showModal, onSubmit, customers, setValue } = props;

  return (
    <div>
      <Dialog open={showModal} onClose={onClose} fullWidth maxWidth={"lg"}>
        <DialogTitle>Create Order</DialogTitle>
        <DialogContent>
          <CreateOrder onSubmit={onSubmit} customers={customers} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
