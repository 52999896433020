import * as React from "react";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function PaymentDetails(props) {
  const { onBack, onNext, value, setValue } = props;

  return (
    <div>
      <MDTypography>Payment Details</MDTypography>
      <MDTypography variant="caption" color="text">
        Payment Details are required.
      </MDTypography>

      <MDInput
        label="Total Bill"
        value={value?.total}
        size="small"
        fullWidth
        disabled
      />
      <MDInput
        label="Discount"
        value={value?.discount}
        size="small"
        fullWidth
        onChange={({ currentTarget }) => {
          const re = /^[0-9\b]+$/;
          if (currentTarget.value === "" || re.test(currentTarget.value))
            setValue({ ...value, discount: currentTarget.value });
        }}
      />
      <MDInput
        label="Billing Address (Optional)"
        value={value?.address}
        size="small"
        fullWidth
        multiline={true}
        minRows={3}
        onChange={({ currentTarget }) => {
          setValue({ ...value, billingAddress: currentTarget.value });
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            onChange={({ target }) => {
              setValue({ ...value, paid: target.checked });
            }}
          />
        }
        label="Paid"
      />

      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt="1.5rem"
      >
        <MDButton variant="gradient" color="secondary" onClick={onBack}>
          Back
        </MDButton>
        <MDButton variant="gradient" color="info" onClick={onNext}>
          Finish
        </MDButton>
      </MDBox>
    </div>
  );
}
