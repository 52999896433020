/** 
  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
import Dashboard from "layouts/dashboard";
import Customers from "layouts/customers";
import Suppliers from "layouts/suppliers";
import Parts from "layouts/parts";
import Orders from "layouts/orders";
import Products from "layouts/products";
import Icon from "@mui/material/Icon";
import Notifications from "layouts/notifications";
import Signin from "layouts/authentication/sign-in";



const routes = [
  {
    type: "title",
    name: "signin",
    key: "signin",
    icon: <Icon fontSize="small">key</Icon>,
    route: "/signin",
    component: <Signin />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Customers",
    key: "customers",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/customers",
    component: <Customers />,
  },
  {
    type: "collapse",
    name: "Suppliers",
    key: "suppliers",
    icon: <Icon fontSize="small">factory</Icon>,
    route: "/suppliers",
    component: <Suppliers />,
  },
  {
    type: "collapse",
    name: "Parts",
    key: "parts",
    icon: <Icon fontSize="small">construction</Icon>,
    route: "/parts",
    component: <Parts />,
  },
  {
    type: "collapse",
    name: "Products",
    key: "products",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/products",
    component: <Products />,
  },
  {
    type: "collapse",
    name: "Sales",
    key: "sales",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/sales",
    component: <Orders />,
  },
  {
    type: "title",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },

];

export default routes;
