import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MultiSelect from "./MultiSelect";

export default function WastePartModal(props) {
  const { onClose, showModal, onSubmit, data } = props;
  const [item, setItem] = React.useState({});
  return (
    <div>
      <Dialog open={showModal} onClose={onClose}>
        <DialogTitle>Remove Part</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Search {props.type} by name to remove stock
          </DialogContentText>

          <Autocomplete
            id="search-part"
            size="small"
            options={data?.map((option) => option.name)}
            renderInput={(params) => (
              <TextField {...params} label={"Search " + props.type} />
            )}
            onChange={(res, value) => {
              // console.log(data.filter((item) => item.name == value)[0]);
              setItem({
                ...item,
                part: value,
                partId: data.filter((item) => item.name == value)[0]?._id,
                parts: data
                  ?.filter((item) => item.name == value)[0]
                  ?.parts?.map((a) => {
                    return {
                      quantity: a.quantity,
                      _id: a._id._id,
                    };
                  }),
              });
            }}
            value={item?.part}
            sx={{ marginTop: "2rem", marginBottom: "0.1rem" }}
          />
          <MDInput
            // purchaseUnit
            // label="asdsad"
            label={"Number of parts wasted"}
            size="small"
            fullWidth
            value={item?.quantity}
            onChange={({ currentTarget }) =>
              setItem({
                ...item,
                quantity: currentTarget.value,
              })
            }
            disabled={item?.kgs && item.kgs > 0}
            type="number"
          />
          <MDInput
            // purchaseUnit
            // label="asdsad"
            label={"waste kgs"}
            size="small"
            fullWidth
            value={item?.kgs}
            disabled={item.quantity > 0}
            onChange={({ currentTarget }) => {
              let selectedPart = data.filter((a) => a._id == item.partId)[0];
              setItem({
                ...item,
                kgs: currentTarget.value,
                selectedPartWeight: selectedPart.weight,
              });
            }}
            type="number"
          />
          <MDInput
            label="Reason for wastage"
            value={item?.textarea}
            size="small"
            multiline={true}
            minRows={3}
            fullWidth
            onChange={({ currentTarget }) => {
              setItem({ ...item, reason: currentTarget.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={() => {
              let a = {
                ...item,
                oldQuantity:
                  data.filter((a) => a._id === item.partId)[0]?.quantity || 0,
              };

              onSubmit(a);
              setItem({});
            }}
          >
            {props.loading ? (
              <CircularProgress size={20} color="info" />
            ) : (
              "remove"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
