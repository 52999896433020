import * as React from "react";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Autocomplete, TextField } from "@mui/material";

export default function CustomerDetails(props) {
  const { onBack, onNext, customers } = props;
  const [customer, setCustomer] = React.useState({});

  return (
    <div>
      <MDTypography>Customer Details</MDTypography>
      <MDTypography variant="caption" color="text">
        Order Details are required.
      </MDTypography>

      <Autocomplete
        id="search-supplier"
        size="small"
        options={customers?.map((option) => option.name)}
        renderInput={(params) => <TextField {...params} label="Customer" />}
        onChange={
          (res, value) =>
            setCustomer(
              customers?.filter((item) => item.name === value)[0] || {}
            )
          // setItem({
          //   ...item,
          //   customer: value,
          //   customerId: customers?.filter((item) => item.name == value)[0]._id,
          // })
        }
        value={customer?.name}
        sx={{ marginTop: "0.6rem" }}
      />
      <MDTypography variant="h5">Name: {customer?.name} </MDTypography>
      <MDTypography variant="subtitle2">
        Phone #: {customer?.contact}
      </MDTypography>
      <MDTypography variant="subtitle2">
        Address: {customer?.address}
      </MDTypography>

      {/* <MDInput
        label="Full Name"
        value={customer?.name}
        size="small"
        fullWidth
        // onChange={({ currentTarget }) => {
        //   setValue({ ...value, name: currentTarget.value });
        // }}
      />
      <MDInput
        label="Phone"
        value={customer?.contact}
        size="small"
        fullWidth
        // onChange={({ currentTarget }) => {
        //   const re = /^[0-9\b]+$/;
        //   if (currentTarget.value === "" || re.test(currentTarget.value))
        //     setValue({ ...value, phone: currentTarget.value });
        // }}
      />
      <MDInput
        label="Address"
        value={customer?.address}
        size="small"
        fullWidth
        multiline={true}
        minRows={3}
        // onChange={({ currentTarget }) => {
        //   setValue({ ...value, address: currentTarget.value });
        // }}
      /> */}

      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt="1.5rem"
      >
        <MDButton variant="gradient" color="secondary" onClick={onBack}>
          Back
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          onClick={() => {
            onNext(customer);
          }}
          disabled={!customer?.name}
        >
          Next
        </MDButton>
      </MDBox>
    </div>
  );
}
