import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Divider } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  circle: {
    width: 13,
    height: 13,
    borderRadius: 13,
    backgroundColor: "#fff",
    border: "2px solid #bdbdbd",
  },
  activeCircle: {
    width: 13,
    height: 13,
    borderRadius: 1,
    backgroundColor: "#747b8a",
    border: "2px solid #747b8a",
  },
  line: {
    width: "30%",
    height: 1.5,
    backgroundColor: "#bdbdbd",
  },
  activeLine: {
    width: "30%",
    height: 1.5,
    backgroundColor: "#747b8a",
  },
});
const getStatus = {
  Order: 1,
  Customer: 2,
  Part: 3,
  Payment: 4,
};
function ProgressTimeline({ active }) {
  const classes = useStyles();

  return (
    <MDBox width="100%" >
      <MDBox sx={{ display: "flex", alignItems: "center", px: 5, mb: 2 }}>
        <Box
          className={
            getStatus[active] >= 1 ? classes.activeCircle : classes.circle
          }
        />
        <Box
          className={getStatus[active] > 1 ? classes.activeLine : classes.line}
        />
        <Box
          className={
            getStatus[active] >= 2 ? classes.activeCircle : classes.circle
          }
        />
        <Box
          className={getStatus[active] > 2 ? classes.activeLine : classes.line}
        />
        <Box
          className={
            getStatus[active] >= 3 ? classes.activeCircle : classes.circle
          }
        />
        <Box
          className={getStatus[active] > 3 ? classes.activeLine : classes.line}
        />
        <Box
          className={
            getStatus[active] >= 4 ? classes.activeCircle : classes.circle
          }
        />
      </MDBox>
      <MDBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {["Order", "Customer", "Product", "Payment"].map((item, index) => (
          <MDTypography key={index} variant="button" color="#bdbdbd">
            {getStatus[item]}. {item} Details
          </MDTypography>
        ))}
      </MDBox>
    </MDBox>
  );
}

export default ProgressTimeline;
