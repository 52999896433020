import { CUSTOMER, RESET } from "./types";

export const addCustomers = (custoemrs) => {
  return {
    type: CUSTOMER,
    payload: custoemrs,
  };
};
export const resetCustomer = () => ({
  type: RESET,
});
