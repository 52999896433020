import { Icon, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";

export function metaData() {
  return {
    columns: [
      { Header: "Item No.", accessor: "no", align: "left" },
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "Cost Price", accessor: "price", align: "left" },
      { Header: "Weight (g)", accessor: "weight", align: "left" },
      { Header: "Quantity", accessor: "quantity", align: "left" },
      {
        Header: "Total (kg/pcs/meter)",
        accessor: "totalWeight",
        align: "left",
      },
      { Header: "Added on", accessor: "added", align: "left" },
      { Header: "Last updated", accessor: "updated", align: "center" },
      { Header: "Actions", accessor: "action", align: "center" },
    ],
  };
}

export function row(
  item,
  index,
  addStock,
  handleHistory,
  handleEdit,
  handleDelete
) {
  let cost = item.parts
    .map((a) => a._id?.price * a.quantity)
    .reduce((a, b) => a + b, 0);
  if (index === 0) {
    console.log("Product Item: ", item);
  }
  return {
    no: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {index + 1}
      </MDTypography>
    ),
    name: (
      <MDTypography variant="button" fontWeight="medium">
        {item.name}
      </MDTypography>
    ),
    price: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {Math.ceil(cost)}
      </MDTypography>
    ),
    weight: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {item.weight}
      </MDTypography>
    ),
    quantity: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {item.quantity}
      </MDTypography>
    ),
    totalWeight: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {(item?.quantity * item.weight) / 1000}
      </MDTypography>
    ),
    added: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {moment(item.createdAt).format("DD/MM/YYYY")}
      </MDTypography>
    ),
    updated: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {moment(item.updatedAt).format("DD/MM/YYYY")}
      </MDTypography>
    ),
    action: (
      <MDBox sx={{ display: "inline" }}>
        <IconButton onClick={() => addStock(item)}>
          <AddIcon fontSize="small">Add</AddIcon>
        </IconButton>
        <IconButton onClick={() => handleHistory(item)}>
          <Icon fontSize="small">history</Icon>
        </IconButton>
        <IconButton onClick={() => handleEdit(item)}>
          <Icon fontSize="small">edit</Icon>
        </IconButton>
        <IconButton onClick={() => handleDelete(item)}>
          <Icon fontSize="small">delete</Icon>
        </IconButton>
      </MDBox>
    ),
  };
}

export const mock = [
  {
    no: "1001",
    name: "Motorcycle back stand",
    quantity: [{ value: "30", addedOn: new Date() }],
    price: "1000",
    weight: "105g",
    sku: "UGG-BB-PUR-06",
  },
  {
    no: "1002",
    name: "Parking standing Single",
    quantity: [{ value: "30", addedOn: new Date() }],
    price: "999",
    weight: "165g",
    sku: "UGG-BB-PUR-06",
  },
  {
    no: "1003",
    name: "Foot-Rest",
    quantity: [{ value: "30", addedOn: new Date() }],
    price: "1299",
    gage: "10",
    sku: "UGG-BB-PUR-06",
  },
];
