import { Icon, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";

export function metaData() {
  return {
    columns: [
      { Header: "Order Id.", accessor: "id", align: "left" },
      { Header: "Date", accessor: "date", align: "left" },
      { Header: "Status", accessor: "status", align: "left" },
      { Header: "Customer", accessor: "customer", align: "left" },
      { Header: "Product", accessor: "product", align: "left" },
      { Header: "Total Amount", accessor: "revenue", align: "left" },
      { Header: "Discount", accessor: "discount", align: "left" },
      { Header: "Grand Total", accessor: "net", align: "left" },
      // { Header: "Actions", accessor: "action", align: "center" },
    ],
  };
}

const statusColors = ["orange", "blue", "green", "red", "black"];
export function row(item, handleDelete) {
  return {
    id: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        #{item.invoiceNumber}
      </MDTypography>
    ),
    date: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {moment(item?.createdAt).format("DD MMM, YYYY")}
      </MDTypography>
    ),
    status: (
      <MDTypography
        variant="button"
        fontWeight="medium"
        sx={{
          color: statusColors[item.status],
        }}
      >
        {item.status || "Un-Paid"}
      </MDTypography>
    ),
    customer: (
      <MDBox display="flex" flexDirection={"column"}>
        <MDTypography variant="p" color="#000" fontWeight="medium">
          {item?.customer?.name}
        </MDTypography>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {item.customer?.contact}
        </MDTypography>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {item.customer?.address}
        </MDTypography>
      </MDBox>
    ),
    product: (
      <MDBox display="flex" flexDirection={"column"} width="10rem">
        {item.products.map((a, index) => (
          <MDBox key={index} display="flex" flexDirection={"row"}>
            <MDTypography variant="p" color="#000" fontWeight="medium">
              {a?._id?.name || ""}
            </MDTypography>
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {a?.quantity}
            </MDTypography>
          </MDBox>
        ))}
      </MDBox>
    ),
    revenue: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {new Intl.NumberFormat("pk-PK", {
          style: "currency",
          currency: "PKR",
        }).format(item.salePrice)}
      </MDTypography>
    ),
    discount: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {new Intl.NumberFormat("pk-PK", {
          style: "currency",
          currency: "PKR",
        }).format(item.discount || 0)}
      </MDTypography>
    ),
    net: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {new Intl.NumberFormat("pk-PK", {
          style: "currency",
          currency: "PKR",
        }).format(parseFloat(item.salePrice) - parseFloat(item.discount || 0))}
      </MDTypography>
    ),
    // action: (
    //   <MDBox sx={{ display: "inline" }}>
    //     <IconButton
    //       sx={{ marginLeft: "2rem" }}
    //       onClick={() => alert("Order Details")}
    //     >
    //       <Icon fontSize="small">visibility</Icon>
    //     </IconButton>
    //     <IconButton
    //       sx={{ marginLeft: "1rem" }}
    //       onClick={() => handleDelete(item)}
    //     >
    //       <Icon fontSize="small">delete</Icon>
    //     </IconButton>
    //   </MDBox>
    // ),
  };
}

export const mock = [];
