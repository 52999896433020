import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MultiSelect from "./MultiSelect";

export default function StockModal(props) {
  const { onClose, showModal, onSubmit, data, selectedItem } = props;
  const [item, setItem] = React.useState({});
  return (
    <div>
      <Dialog open={showModal} onClose={onClose}>
        <DialogTitle>Add New Stock</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Search {props.type} by name to update stock
          </DialogContentText>
          <Autocomplete
            id="search-part"
            size="small"
            options={data?.map((option) => option.name)}
            renderInput={(params) => (
              <TextField {...params} label={"Search " + props.type} />
            )}
            value={selectedItem?.name}
            sx={{ marginTop: "2rem", marginBottom: "0.1rem" }}
            disabled
          />
          <MDInput
            // purchaseUnit
            // label="asdsad"
            label={
              props.type == "part"
                ? `New stock in total ${item?.purchaseUnit || "kg"}`
                : "Add Number of products acquired"
            }
            size="small"
            fullWidth
            value={item?.quantity}
            onChange={({ currentTarget }) =>
              setItem({
                ...item,
                quantity: currentTarget.value,
              })
            }
            type="number"
          />

          {props.type == "part" && (
            <MDInput
              label="Total Stock Price"
              size="small"
              fullWidth
              value={item?.price}
              onChange={({ currentTarget }) =>
                setItem({ ...item, price: currentTarget.value })
              }
            />
          )}
          {props.type == "part" && (
            <Autocomplete
              id="search-supplier"
              size="small"
              options={props?.suppliers?.map((option) => option.name)}
              renderInput={(params) => (
                <TextField {...params} label="Supplier" />
              )}
              onChange={(res, value) =>
                setItem({
                  ...item,
                  supplier: value,
                  supplierId: props.suppliers.filter(
                    (item) => item.name == value
                  )[0]._id,
                })
              }
              value={item?.supplier}
              sx={{ marginTop: "0.6rem" }}
            />
          )}

          {/* {props.type == "product" && (
            <MultiSelect
              options={["Part 1", "Part 2", "Part 3"]}
              selected={selected}
              setSelected={setSelected}
              label="Select Parts"
              height={35}
            />
          )} */}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={() => {
              console.log();
              let a = {
                ...item,
                oldQuantity: selectedItem.quantity,
                partId: selectedItem._id,
                purchaseUnit: selectedItem.purchase_unit,
              };
              onSubmit(a);
            }}
          >
            {props.loading ? (
              <CircularProgress size={20} color="info" />
            ) : (
              "Add"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
