import { combineReducers, createStore } from "redux";
import UserReducer from "reduxStore/user/reducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import SupplierReducer from "./supplier/reducer";
import CustomerReducer from "./customer/reducer";
const rootReducer = combineReducers({
  user: UserReducer,
  supplier:SupplierReducer,
  customer:CustomerReducer
});

const persistConfig = {
  key: "root",
  storage: storage,
   whitelist: ['user','supplier','customer'],
   blacklist: []
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);
let persistor = persistStore(store);
export { store, persistor };
