import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useNavigate } from "react-router-dom";
import MDSnackbar from "components/MDSnackbar";
import AuthRequets from "services/Auth/AuthRequests";
import axios from "axios";
import { setToken } from "services/Contants";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { login } from "reduxStore/user/action";
function Basic() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("admin@alhadded.com");
  const [password, setPassword] = useState("password");
  const [errorSB, setErrorSB] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const handleLogin = () => {
    setLoading(true);
    AuthRequets.login({
      email: email,
      password: password,
    })
      .then((res) => {
        let reqInstance = axios.create({
          headers: {
            Authorization: `Bearer ${res.data}`,
          },
        });
        dispatch(login(res.data));
        setToken(reqInstance);
      })
      .catch((rej) => {
        alert("Database error")
        alert(rej.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Al Hadeed Admin
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton
                onClick={handleLogin}
                variant="gradient"
                color="info"
                fullWidth
              >
                {loading ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  "sign in"
                )}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        color="error"
        icon="warning"
        title="Wrong credentials"
        content="Wrong password entered"
        dateTime=""
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
      />
    </BasicLayout>
  );
}

export default Basic;
