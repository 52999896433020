import { API_URL } from "services/Contants";
import axios from "axios";

const AuthRequets = {
  login: (param) => {
    return new Promise((resolve, reject) => {
      var data = JSON.stringify(param);

      var config = {
        method: "post",
        url: `${API_URL}/auth/login`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
};

export default AuthRequets;
