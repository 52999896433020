import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import DataTable from 'examples/Tables/DataTable';
import MDInput from 'components/MDInput';
import { metaData, row, mock } from 'layouts/customers/data/customerTableData';
import { useState, useEffect, useCallback } from 'react';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CustomerModal from 'components/CustomComponents/CustomerModal';
import CustomerRequests from 'services/Customers/CustomerRequests';
import Pagination from '@mui/material/Pagination';

function Customer() {
  const { columns } = metaData();
  const token = useSelector((state) => state.user.token);
  const [search, setSearch] = useState('');
  const [rows, setRows] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [customer, setCustomer] = useState({});
  const dispatch = useDispatch();
  const [totalPages, setTotalPages] = useState(10);
  const [page, setPage] = useState(1);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (arg) => {
    onAddCustomer();
    setCustomer(arg);
  };

  const handleDelete = (arg) => {
    handleClickOpen();
    setUserId(arg._id);
  };

  const deleteAccount = () => {
    handleClose();
    CustomerRequests.removeCustomer(userId)
      .then((res) => {
        fetchData();
      })
      .catch((rej) => {
        console.log(rej);
      });
  };

  const onAdd = () => {
    if (customer.name == undefined || customer.name == '') {
      alert('Please enter customer name');
    } else if (customer.contact == undefined || customer.contact == '') {
      alert('Please enter customer contact number');
    } else if (customer.address == undefined || customer.address == '') {
      alert('Please enter customer address');
    } else {
      setUploading(true);
      CustomerRequests.addCustomer(customer)
        .then((res) => {
          fetchData();
          setShowModal(false);
          setCustomer({});
        })
        .catch((rej) => {
          console.log(rej);
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const onUpdate = () => {
    if (customer.name == undefined || customer.name == '') {
      alert('Please enter customer name');
    } else if (customer.contact == undefined || customer.contact == '') {
      alert('Please enter customer contact number');
    } else if (customer.address == undefined || customer.address == '') {
      alert('Please enter customer address');
    } else {
      setUploading(true);
      CustomerRequests.updateCustomer(
        {
          name: customer.name,
          address: customer.address,
          contact: customer.contact,
        },
        customer._id
      )
        .then((res) => {
          fetchData();
          setShowModal(false);
          setCustomer({});
        })
        .catch((rej) => {
          console.log(rej);
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const onAddCustomer = () => {
    setCustomer({});
    setShowModal(true);
  };

  const onSearchChange = (arg) => {
    setRows(
      data
        .filter((item) => {
          let nameLowerCase = item.name.toLowerCase();
          let searchTermLowerCase = arg.toLowerCase();
          return nameLowerCase.indexOf(searchTermLowerCase) > -1;
        })
        .map((item) => {
          return row(item, handleEdit, handleDelete);
        })
    );
  };

  const onChangePagination = (event, value) => {
    setPage(value);
    fetchData(value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (value) => {
    setLoading(true);
    const p = value ? value : page
    console.log({
      limit: 10,
      offset: (p - 1) * 10,
      query: search,
    });
    CustomerRequests.getCustomers({
      limit: 10,
      offset: (p - 1) * 10,
      query: search,
    })
      .then(async (res) => {
        let results = await res.data.map((item) =>
          row(item, handleEdit, handleDelete)
        );
        setRows(results);
        setData(res.data);
        setTotalPages(Math.ceil(res.total / 10));
      })
      .catch((rej) => {
        console.log(rej);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant='gradient'
                bgColor='info'
                borderRadius='lg'
                coloredShadow='info'
              >
                <MDTypography variant='h6' color='white'>
                  All customers
                </MDTypography>
              </MDBox>

              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  searchComponent={
                    <MDBox
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      p={3}
                    >
                      <MDBox
                        width='60%'
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <MDButton
                          onClick={onAddCustomer}
                          variant='gradient'
                          color='info'
                          style={{ margin: '10px', width: 180 }}
                        >
                          {false ? (
                            <CircularProgress size={20} color='white' />
                          ) : (
                            'Add customer'
                          )}
                        </MDButton>
                      </MDBox>
                      {/* <MDBox width='12rem' ml='auto'>
                        <MDInput
                          placeholder='Search...'
                          value={search}
                          size='small'
                          fullWidth
                          onChange={({ currentTarget }) => {
                            setSearch(currentTarget.value);
                            onSearchChange(currentTarget.value);
                          }}
                        />
                      </MDBox> */}
                    </MDBox>
                  }
                />

                <Grid
                  container
                  spacing={3}
                  alignItems='center'
                  justifyContent='center'
                  sx={{ marginBottom: '3rem', marginTop: '1rem' }}
                >
                  {loading ? (
                    <CircularProgress size={30} color='black' />
                  ) : rows.length < 1 ? (
                    <MDTypography
                      variant='button'
                      color='text'
                      fontWeight='regular'
                    >
                      No customer available
                    </MDTypography>
                  ) : null}
                </Grid>
                <Grid
                  container
                  spacing={3}
                  marginY={2}
                  alignItems='center'
                  justifyContent='flex-end'
                >
                  <Pagination
                    color='primary'
                    count={totalPages}
                    page={page}
                    onChange={onChangePagination}
                  />
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <CustomerModal
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        onSubmit={onAdd}
        onUpdate={onUpdate}
        customer={customer}
        setCustomer={setCustomer}
        loading={uploading}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Delete Customer?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure about deleting account? It cannot be recovered once
            deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={deleteAccount} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Customer;
