import { CUSTOMER ,RESET} from "./types";

const initialState = {
  customers: [],
};

const CustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER:
      return {
        ...state,
        customers: action.payload,
      };
      case RESET:
        return {
          ...state,
          customers: null,
        };

    default:
      return state;
  }
};

export default CustomerReducer;
