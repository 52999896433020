import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDInput from "components/MDInput";
import { metaData, row, mock } from "layouts/parts/data/partsTableData";
import UserRequets from "services/User/UserRequests";
import { useState, useEffect, useRef } from "react";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PartsModal from "components/CustomComponents/PartsModal";
import StockModal from "components/CustomComponents/StockModal";
import WastePartModal from "components/CustomComponents/WastePartModal";
import StockHistoryModal from "components/CustomComponents/StockHistoryModal";
import PartRequests from "services/Parts/PartRequests";
import SupplierRequests from "services/Supplier/SupplierRequests";
import { addSuppliers } from "reduxStore/supplier/action";
import Pagination from "@mui/material/Pagination";
import _ from "lodash";

const LIMIT = 10;

function Tables() {
  const { columns } = metaData();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const suppliers = useSelector((state) => state.supplier.suppliers);
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [part, setPart] = useState({
    purchase_unit: "",
  });
  const [partHistory, setPartHistory] = useState([]);
  const [stockModal, setStockModal] = useState(false);
  const [wastePartModal, setWastePartModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [totalPages, setTotalPages] = useState(10);
  const [page, setPage] = useState(1);
  const updateStockRef = useRef(null);

  useEffect(() => {
    fetchData();
    getAllSuppliers();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (arg) => {
    onAddPart();
    setPart({
      ...arg,
      supplier: arg.supplier
        ? suppliers.filter((item) => item._id === arg.supplier)[0].name
        : "",
    });
  };

  const handleHistory = (arg) => {
    setPartHistory(arg.purchasing);
    setHistoryModal(true);
  };

  const addStock = (arg) => {
    // arg
    updateStockRef.current = arg;
    setStockModal(true);
  };

  const handleDelete = (arg) => {
    handleClickOpen();
    setUserId(arg._id);
  };

  const deleteAccount = () => {
    handleClose();
    PartRequests.removePart(userId)
      .then((res) => {
        fetchData();
      })
      .catch((rej) => {
        console.log(rej);
      });
  };

  const onAdd = () => {
    if (part.name == undefined || part.name == "") {
      alert("Please enter part name");
    } else if (part.used_for == undefined || part.used_for == "") {
      alert("Please enter used for");
    } else if (
      part.weight == undefined || part.weight == ""
        ? part.gauge == undefined || part.gauge == ""
        : part.weight == undefined || part.weight == ""
    ) {
      alert("Please enter weight or gauge");
    } else if (part.purchase_unit == undefined || part.purchase_unit == "") {
      alert("Please select purchase unit");
    } else if (
      part.production_unit == undefined ||
      part.production_unit == ""
    ) {
      alert("Please select production unit");
    }
    // else if (part.per_peice_attr == undefined || part.per_peice_attr == "") {
    //   alert("Please enter conversion");
    // }
    else {
      setUploading(true);
      PartRequests.addPart(
        part.weight != null
          ? {
              name: part.name,
              purchase_unit: part.purchase_unit,
              used_for: part.used_for,
              weight: part.weight,
              per_peice_attr: part.per_peice_attr,
              textarea: part.textarea,
              production_unit: part.production_unit,
            }
          : {
              name: part.name,
              purchase_unit: part.purchase_unit,
              used_for: part.used_for,
              gauge: part.gauge,
              per_peice_attr: part.per_peice_attr,
              textarea: part.textarea,
              production_unit: part.production_unit,
            }
      )
        .then((res) => {
          if (res.data.httpCode === 400) {
            console.log("Error: ", res.data);
          } else {
            console.log("asdfsdf: ", res);
            fetchData();
            setShowModal(false);
            // setPart({});
          }
        })
        .catch((err) => {
          console.log("Reponse: ", err);
          // console.log(rej);
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const handleAddStock = (stock) => {
    if (!stock.partId) {
      alert("Please select a part");
    } else if (!stock.quantity) {
      alert("Please enter qunatity for part");
    } else if (!stock.price) {
      alert("Please enter price for part");
    } else if (!stock.supplierId) {
      alert("Please select a supplier");
    } else {
      setUploading(true);
      let d = {
        price: parseInt(stock.price),
        supplier: stock.supplierId,
        id: stock.partId,
      };
      if (stock.purchaseUnit === "kg") {
        d["weight"] = stock.quantity;
      } else if (stock.purchaseUnit === "pcs") {
        d["pcs"] = stock.quantity;
      } else if (stock.purchaseUnit === "mtr") {
        d["length"] = stock.quantity;
      }
      PartRequests.addStock(d)
        .then((res) => {
          fetchData();
          setStockModal(false);
        })
        .catch((rej) => {
          alert(rej.response.data.message);
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const removeStockPart = (stock) => {
    if (!stock.partId) {
      alert("Please select a part");
    } else if (!!stock.quantity && !!stock.kgs) {
      alert("Please enter qunatity for part or add kgs");
    } else {
      setUploading(true);
      let d = {
        reason: stock.reason,
        quantity: stock.kgs
          ? (stock.kgs * 1000) / stock.selectedPartWeight
          : parseInt(stock.quantity),
        id: stock.partId,
      };
      PartRequests.wastePart(d)
        .then((res) => {
          fetchData();
        })
        .catch((rej) => {
          alert(rej.response.data.message);
        })
        .finally(() => {
          setUploading(false);
          setWastePartModal(false);
        });
    }
  };

  const onUpdate = () => {
    if (part.name == undefined || part.name == "") {
      alert("Please enter part name");
    } else if (part.used_for == undefined || part.used_for == "") {
      alert("Please enter used for");
    } else if (
      part.weight == undefined || part.weight == ""
        ? part.gauge == undefined || part.gauge == ""
        : part.weight == undefined || part.weight == ""
    ) {
      alert("Please enter weight or gauge");
    } else if (part.purchase_unit == undefined || part.purchase_unit == "") {
      alert("Please select purchase unit");
    } else if (
      part.production_unit == undefined ||
      part.production_unit == ""
    ) {
      alert("Please select production unit");
    } else {
      // setUploading(true);
      let dat = {
        name: part.name,
        purchase_unit: part.purchase_unit,
        used_for: part.used_for,
        per_peice_attr: part.per_peice_attr,
        textarea: part.textarea,
        production_unit: part.production_unit,
        weight: part?.weight,
      };
      PartRequests.updatePart(dat, part._id)
        .then((res) => {
          fetchData();
          setShowModal(false);
        })
        .catch((rej) => {
          console.log(rej);
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const onAddPart = () => {
    setPart({});
    setShowModal(true);
  };

  const onAddStock = () => {
    // setStockModal(true);
  };

  const onSearchChange = (arg) => {
    setRows(
      data
        .filter((item) => {
          let nameLowerCase = item.name.toLowerCase();
          let searchTermLowerCase = arg.toLowerCase();
          return nameLowerCase.indexOf(searchTermLowerCase) > -1;
        })
        .map((item) => {
          return row(
            item,
            suppliers,
            addStock,
            handleHistory,
            handleEdit,
            handleDelete
          );
        })
    );
  };

  const debouncedSearch = _.debounce(async (q) => {
    fetchData(0);
  }, 1000);

  const getAllSuppliers = () => {
    SupplierRequests.getSupplier({
      limit: 999999,
      offset: 0,
      query: "",
    })
      .then(async (res) => {
        dispatch(addSuppliers(res.data));
      })
      .catch((rej) => {
        console.log(rej);
      })
      .finally(() => {});
  };

  const onChangePagination = (event, value) => {
    setPage(value);
    fetchData(value);
  };

  const fetchData = (value) => {
    setLoading(true);
    const p = value ? value : page;
    PartRequests.getParts({
      limit: LIMIT,
      offset: (p - 1) * LIMIT,
      query: search,
      sku: "",
    })
      .then(async (res) => {
        let results = await res.data.map((item, index) =>
          row(
            item,
            suppliers,
            addStock,
            handleHistory,
            handleEdit,
            handleDelete
          )
        );
        setRows(results);
        setData([...res.data]);
        setTotalPages(Math.ceil(res.total / LIMIT));
      })
      .catch((rej) => {
        console.log(rej);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Spare Parts
                </MDTypography>
              </MDBox>

              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  pageOptions={[]}
                  searchComponent={
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <MDBox
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          ml={2}
                          mr={2}
                          mb={2}
                        >
                          <MDButton
                            onClick={onAddPart}
                            variant="gradient"
                            color="info"
                            style={{ margin: "10px", width: 180 }}
                          >
                            {false ? (
                              <CircularProgress size={20} color="white" />
                            ) : (
                              "Add Part"
                            )}
                          </MDButton>
                          {/* <MDButton
                            onClick={onAddStock}
                            variant="gradient"
                            color="info"
                            style={{ margin: "10px", width: 180 }}
                          >
                            Add New Stock
                          </MDButton> */}
                          <MDButton
                            onClick={() => setWastePartModal(true)}
                            variant="gradient"
                            color="error"
                            style={{ margin: "10px", width: 180 }}
                          >
                            Waste Part
                          </MDButton>
                          {/* <MDBox ml="auto">
                            <MDInput
                              placeholder="Search..."
                              value={search}
                              size="small"
                              fullWidth
                              onChange={({ currentTarget }) => {
                                setSearch(currentTarget.value);
                                onSearchChange(currentTarget.value);
                              }}
                            />
                          </MDBox> */}
                        </MDBox>
                      </Grid>
                    </Grid>
                  }
                />

                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ marginBottom: "3rem", marginTop: "1rem" }}
                >
                  {loading ? (
                    <CircularProgress size={30} color="black" />
                  ) : rows.length < 1 ? (
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                    >
                      No parts available
                    </MDTypography>
                  ) : null}
                </Grid>
                <Grid
                  container
                  spacing={3}
                  marginY={2}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Pagination
                    color="primary"
                    count={totalPages}
                    page={page}
                    onChange={onChangePagination}
                  />
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <PartsModal
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        onSubmit={onAdd}
        onUpdate={onUpdate}
        value={part}
        setValue={setPart}
        suppliers={suppliers}
        loading={uploading}
      />
      <StockModal
        showModal={stockModal}
        onClose={() => {
          setStockModal(false);
        }}
        data={data}
        onSubmit={handleAddStock}
        selectedItem={updateStockRef.current}
        type="part"
        suppliers={suppliers}
        loading={uploading}
      />
      <WastePartModal
        showModal={wastePartModal}
        onClose={() => {
          setWastePartModal(false);
        }}
        data={data}
        onSubmit={removeStockPart}
        type="part"
        loading={uploading}
      />
      <StockHistoryModal
        showModal={historyModal}
        onClose={() => {
          setHistoryModal(false);
        }}
        data={partHistory}
        suppliers={suppliers}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Part?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure about deleting account? It cannot be recovered once
            deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={deleteAccount} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Tables;
