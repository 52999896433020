import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MultiSelect from "./MultiSelect";
import {
  Autocomplete,
  CircularProgress,
  Icon,
  IconButton,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";

export default function PartsModal(props) {
  const {
    onClose,
    showModal,
    onSubmit,
    onUpdate,
    value,
    setValue,
    data,
    loading,
    selectedItem,
  } = props;
  const [selectedParts, setSelectedParts] = React.useState([]);

  React.useEffect(() => {
    setSelectedParts([]);
    if (selectedItem?._id != undefined) {
      if (selectedItem?.parts?.length > 0) {
        let res = selectedItem.parts
          .map((item) => {
            if (item && item?._id && item?._id?.name && item?._id?._id) {
              console.log("item._id: ", item._id);
              return {
                part: item?._id.name,
                partId: item._id._id,
                quantity: item.quantity,
              };
            }
          })
          .filter((a) => a);
        setSelectedParts(res);
      }
    }
  }, [showModal]);

  React.useEffect(() => {
    setValue({
      ...value,
      parts: selectedParts.map((item) => ({
        _id: item.partId,
        quantity: item.quantity || 1,
      })),
    });
  }, [selectedParts]);
  return (
    <div>
      <Dialog open={showModal} onClose={onClose}>
        <DialogTitle>
          {value?._id != undefined ? "Update" : "Add"} Product
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill in the basic details of product.
          </DialogContentText>

          <MDInput
            label="Name"
            value={value?.name}
            size="small"
            fullWidth
            onChange={({ currentTarget }) => {
              setValue({ ...value, name: currentTarget.value });
            }}
            // disabled={value?._id != undefined}
          />
          <MDInput
            label="Quantity"
            value={value?.quantity}
            size="small"
            fullWidth
            onChange={({ currentTarget }) => {
              setValue({ ...value, quantity: currentTarget.value });
            }}
            type="number"
            disabled={value?._id != undefined}
          />
          <MDInput
            label="Weight per product (g)"
            value={value?.weight}
            size="small"
            fullWidth
            onChange={({ currentTarget }) => {
              setValue({ ...value, weight: currentTarget.value });
            }}
            // disabled={value?._id != undefined}
            type="number"
          />
          <Autocomplete
            id="search-part"
            size="small"
            options={data?.map((option) => option.name)}
            renderInput={(params) => (
              <TextField {...params} label="Select parts" />
            )}
            onChange={(res, val) => {
              setSelectedParts([
                ...selectedParts,
                {
                  part: val,
                  partId: data.filter((item) => item.name == val)[0]?._id,
                  quantity: 1,
                },
              ]);
            }}
            sx={{ marginTop: "0.7rem", marginBottom: "0.1rem" }}
          />
          {selectedParts?.map((item, index) => (
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <MDInput
                label="Part"
                value={item?.part}
                size="small"
                fullWidth
                disabled={true}
              />
              <MDInput
                label="Quantity"
                type="number"
                value={item?.quantity}
                size="small"
                fullWidth
                sx={{
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onChange={({ currentTarget }) => {
                  if (parseInt(currentTarget.value) > 0) {
                    selectedParts[index].quantity = currentTarget.value;
                    setSelectedParts([...selectedParts]);
                  } else {
                    alert("Quantity must be greater than zero");
                  }
                  // if (
                  //   currentTarget.value == "" ||
                  //   parseInt(currentTarget.value) > 0
                  // ) {
                  //   setSelectedParts(
                  //     selectedParts.map((part, i) => {
                  //       if (i == index) {
                  //         return {
                  //           ...part,
                  //           quantity: currentTarget.value,
                  //         };
                  //       }
                  //       return part;
                  //     })
                  //   );
                  // } else {
                  //   alert("Quantity must be greater than zero");
                  // }
                }}
              />
              <IconButton
                sx={{ marginTop: "10px" }}
                onClick={() => {
                  setSelectedParts(
                    selectedParts.filter((part, i) => i != index)
                  );
                }}
              >
                <Icon fontSize="small">cancel</Icon>
              </IconButton>
            </MDBox>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={value?._id != undefined ? onUpdate : onSubmit}>
            {loading ? (
              <CircularProgress size={20} color="info" />
            ) : value?._id != undefined ? (
              "Update"
            ) : (
              "Add"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
