import axios from "axios";

// export const API_URL = "https://al-hadeed.herokuapp.com/api"; 

export const API_URL = "https://al-hadeed-prod.herokuapp.com/api"; 


let axoisInstance = axios;
export const setToken = (instance) => {
  axoisInstance = instance;
};

export { axoisInstance };
